import Button from '@atom/Button'
import * as Config from '../../config'

export default function Form(props: Config.OrderFormProps) {
  const {inputs, onSubmit, form} = props
  const {watch} = form
  const service = watch('service')

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-5 w-full">
        {inputs.name.Control}
        {inputs.service.Control}

        <fieldset disabled={service === 'nu'} className="col-span-2 flex flex-col md:flex-row">
          {inputs['albums[0][size]'].Control}
          {inputs['albums[0][cover]'].Control}
        </fieldset>

        <div className="h-full flex items-center justify-center">
          <Button
            type="submit"
            label="Adaugă"
            variant="primary"
            icon="plus"
            className="rounded-sm"
          />
        </div>
      </div>
    </form>
  )
}

Form.Config = [Config.NameConfig, Config.ServiceConfig, Config.AlbumSizeConfig, Config.CoverConfig]
