import {Portal} from 'react-portal'
import style from './Modal.module.css'

export default function Modal({children, onClose = null}) {
  return (
    <Portal closeOnOutsideClick closeOnEsc>
      <div className={style.background} onClick={onClose}>
        <div className={style.content} style={{}} onClick={(e) => e.stopPropagation()}>
          <div>{children}</div>
        </div>
      </div>
    </Portal>
  )
}
