import classnames from 'classnames'
import {TFormControl} from '@form/types'
import style from './FormControl.module.css'

const DefaultFormControl: TFormControl = ({Label, Input, Error}) => {
  return (
    <div className="w-full">
      <div className={classnames(style.control, style.height)}>
        {Label}
        {Input}
      </div>
      {Error}
    </div>
  )
}

export default DefaultFormControl
