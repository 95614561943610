import Layout from '@organism/Layout'
import OrderForm from '@form/OrderForm'

const IndexPage = () => {
  return (
    <div className="text-center w-full text-gray-off-black flex flex-col items-center p-3 container">
      <h4 className="text-gray-placeholder font-thin my-8">CLASSBOOK 22</h4>
      <h1>Hey, Salut!</h1>

      <OrderForm />
    </div>
  )
}

IndexPage.getLayout = Layout

export default IndexPage
