import * as Config from '../config'
import StepForm from '../StepForm'

export default function Form(props: Config.OrderFormProps) {
  const {inputs} = props

  return (
    <StepForm {...props}>
      {inputs['representant[name]'].Control}
      {inputs['representant[phone]'].Control}
      {inputs['representant[email]'].Control}
    </StepForm>
  )
}
Form.Config = [Config.RepresentantNameConfig, Config.PhoneConfig, Config.EmailConfig]
