import ReactCalendar from 'react-calendar'
import {TFormControl} from '@form/types'

const CalendarControl: TFormControl = ({Input, Error}) => {
  return (
    <div>
      {Input}
      {Error}
    </div>
  )
}

function Calendar({onChange, value}) {
  return (
    <ReactCalendar
      onChange={onChange}
      value={value}
      className="border-2 rounded-xl border-gray-line overflow-hidden"
      minDate={new Date(2022, 3, 1)}
      maxDate={new Date(2022, 11, 31)}
      defaultActiveStartDate={new Date(2022, 3, 15)}
      next2Label={null}
      prev2Label={null}
    />
  )
}
Calendar.Control = CalendarControl

export default Calendar
