import BottomButtons from './BottomButtons'

export default function StepForm({
  children,
  onSubmit,
  form: {
    formState: {isSubmitting},
  },
  goBack,
  step,
}) {
  return (
    <form onSubmit={onSubmit} className="flex flex-col items-center w-full max-w-xl">
      {children}
      <BottomButtons isSubmitting={isSubmitting} goBack={goBack} step={step} />
    </form>
  )
}
