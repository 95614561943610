import classnames from 'classnames'
import {icons} from '@assets/images'

export type IconName = keyof typeof icons

export default function Icon({
  name,
  className,
  ...rest
}: {
  name: IconName
  className?: string
  onClick?: (any) => any
}) {
  return (
    <span>
      <img className={classnames('object-contain', className)} src={icons[name]} {...rest} />
    </span>
  )
}
