import {useFieldArray} from 'react-hook-form'
import Button from '@atom/Button'
import Icon from '@atom/Icon'
import useInput from '@form/components/useInput'
import * as Config from './config'

export default function Form({onSubmit, inputs, form, onClose}: Config.ItemEditFormProps) {
  const {control, watch} = form
  const {fields: albums, append, remove} = useFieldArray({control, name: 'albums'})

  const input = useInput(form)
  const service = watch('service')

  return (
    <form onSubmit={onSubmit}>
      <div className="w-full flex flex-col items-center">
        <h2 className="mb-8 font-bold self-start">Modifică intrarea</h2>
        {inputs['name'].Control}
        {inputs['service'].Control}

        <h5 className="text-gray-body my-3 self-start">Album</h5>
        <fieldset disabled={service === 'nu'} className="col-span-2 flex flex-col">
          {albums.map(({id, size, cover}, index) => (
            <div key={id} className="grid grid-cols-7">
              <div className="col-span-2">
                {
                  input({
                    ...Config.AlbumSizeConfig,
                    name: `albums[${index}].size`,
                    value: size,
                  }).Control
                }
              </div>
              <div className="col-span-4">
                {
                  input({...Config.CoverConfig, name: `albums[${index}].cover`, value: cover})
                    .Control
                }
              </div>
              <div className="col-span-1 flex items-center">
                <Icon name="delete" onClick={() => remove(index)} className="cursor-pointer" />
              </div>
            </div>
          ))}

          <Button
            variant="clear"
            label="Adaugă album extra"
            icon="plusRed"
            className="my-4"
            onClick={() => append({})}
          />
        </fieldset>
        <div className="flex flex-row">
          <Button variant="primary" label="Salvează" icon="plus" type="submit" />
          <Button variant="clear" label="Anulează" onClick={onClose} />
        </div>
      </div>
    </form>
  )
}
Form.Config = [Config.NameConfig, Config.ServiceConfig]
