import {useFieldArray} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import FormError from '@form/components/FormError'
import * as Config from '../config'

import Button from '@atom/Button'
import {useState} from 'react'
import Modal from '@atom/Modal'
import ItemEditForm from '@form/ItemEditForm'
import OrderTable from '@organism/OrderTable'
import AddForm from './AddForm'

export default function Form(props: Config.OrderFormProps) {
  const {onSubmit, form} = props
  const {
    errors,
    control,
    formState: {isSubmitting},
    register,
  } = form
  const {fields: students, append, remove, swap} = useFieldArray({
    control,
    name: 'items',
  })

  const [editIndex, setEditIndex] = useState<false | number>(false)

  const replace = (pos, newData) => {
    const last = students.length - 1
    append(newData)
    swap(pos, last)
    remove(last)
    setEditIndex(false)
  }

  return (
    <div>
      <AddForm onSubmit={append} defaultValues={{}} />
      {editIndex !== false && (
        <Modal>
          <ItemEditForm
            onSubmit={(data) => replace(editIndex, data)}
            defaultValues={students[editIndex]}
            onClose={() => setEditIndex(false)}
          />
        </Modal>
      )}
      <form onSubmit={onSubmit} className="flex flex-col items-center w-full">
        {/* Table */}
        <OrderTable
          register={register}
          students={students}
          setEditIndex={setEditIndex}
          remove={remove}
        />

        <ErrorMessage errors={errors} name="api" render={FormError} />
        <Button
          className="mt-5"
          disabled={isSubmitting || !students?.length}
          label="Trimite comanda"
          variant="outline"
          type="submit"
        />
      </form>
    </div>
  )
}
Form.Config = [Config.DeliveryDate]
