import Calendar from '@molecule/Calendar'
import {FormProps, UseInput, InputConfig} from '../types'

export interface OrderFormValues {
  email: string
  albums: Array<{size: string; cover: string}>
  api: string // error message
}

export interface OrderFormProps extends FormProps<OrderFormValues> {
  inputs: Record<string, UseInput>
  goBack: () => any
  step: number
}

export const CityConfig: InputConfig = {
  id: 'representant[city]',
  name: 'representant[city]',
  label: 'Localitatea',
  type: 'text',
  placeholder: 'ex. Cluj-Napoca',
  bind: {
    required: 'Localitatea e necesară',
  },
}

export const SchoolConfig: InputConfig = {
  id: 'representant[school]',
  name: 'representant[school]',
  label: 'Instituția de învățământ',
  type: 'text',
  placeholder: 'ex. Liceul Nicolae Bălcescu',
  bind: {
    required: 'Școala e necesară',
  },
}

export const ClassConfig: InputConfig = {
  id: 'representant[class]',
  name: 'representant[class]',
  label: 'Clasa',
  type: 'text',
  placeholder: 'ex. 12A',
  bind: {
    required: 'Clasa e necesară',
  },
}

export const RepresentantNameConfig: InputConfig = {
  id: 'representant[name]',
  name: 'representant[name]',
  label: 'Nume Prenume',
  placeholder: 'ex. Popescu Ion',
  type: 'text',
  bind: {
    required: 'Numele e necesar',
  },
}

export const PhoneConfig: InputConfig = {
  id: 'representant[phone]',
  name: 'representant[phone]',
  label: 'Telefon',
  placeholder: 'ex. 0712345678',
  type: 'text',
  bind: {
    required: 'Numărul de telefon e necesar',
  },
}

export const EmailConfig: InputConfig = {
  id: 'representant[email]',
  name: 'representant[email]',
  label: 'E-mail',
  placeholder: 'ex popescu.ion@classbook.com',
  type: 'email',

  bind: {
    required: 'Emailul e necesar',
  },
}

export const DeliveryDate: InputConfig = {
  id: 'deliveryDate',
  name: 'representant[deliveryDate]',
  label: 'Data de predare',
  // placeholder: 'ex popescu.ion@classbook.com',
  component: Calendar,

  bind: {
    required: 'Alege o dată de predare',
  },
}

export const NameConfig: InputConfig = {
  id: 'name', // item[
  name: 'name', // item[
  label: 'Nume, Prenume',
  placeholder: 'ex. Popescu Ion',
  type: 'text',

  bind: {
    required: 'Numele e necesaar',
  },
}

export const ServiceConfig: InputConfig = {
  id: 'service', // item[
  name: 'service', // item[
  label: 'Pachet Classbook',
  placeholder: 'Alege opțiuni',
  type: 'select',
  options: [
    {value: '1 sedinta individuala', label: '1 sedinta individuala'},
    {value: '2 sedinte individuale', label: '2 sedinte individuale'},
  ],

  bind: {
    required: 'Alege',
  },
}

export const AlbumSizeConfig: InputConfig = {
  id: 'albumSize', // item[
  name: 'albums[0][size]', // item[
  label: 'Album',
  placeholder: 'Alege opțiuni',
  type: 'select',
  options: [
    {value: 'S', label: 'S'},
    {value: 'M', label: 'M'},
    {value: 'L', label: 'L'},
  ],

  // bind: {
  //   required: 'Tipul de album e necesaar',
  // },
}

export const CoverConfig: InputConfig = {
  id: 'albumCover', // item[
  name: 'albums[0][cover]', // item[
  label: 'Copertă',
  placeholder: 'Alege opțiuni',
  type: 'select',
  options: [
    {value: 'foto', label: 'Foto'},
    {value: 'piele ecologică', label: 'Piele eco'},
  ],

  // bind: {
  //   required: 'Tipul de copertă e necesaar',
  // },
}
