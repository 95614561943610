import {useCallback} from 'react'
import {useForm} from 'react-hook-form'

import {toastError, toastSuccess} from '@lib/toast'
import useInput from '../useInput'
import {FormContainerProps} from '@form/types'

// parsing errors
// const parseApiErrors = (err) => err?.context?.data?.errors ?? {}

// toasts
const notifySuccess = (data, message = null) => {
  if (typeof message === 'string') toastSuccess(message)
  else if (typeof message === 'function') message(data)
}
const notifyError = (err, message = null) => {
  if (typeof message === 'string') toastError(message)
  else if (typeof message === 'function') message(err)
}

export default function FormContainer({
  render: Form,
  callback,
  defaultValues = {},
  inputs: providedInputs = null,
  successMessage = 'Form submitted successfully',
  errorMessage = 'Error on submitting form',
  ...rest
}: FormContainerProps) {
  const form = useForm({defaultValues: {...defaultValues}})
  const {handleSubmit, clearErrors, setError} = form

  const inputs = (providedInputs || Object.values(Form.Config)).map(useInput(form))
  const inputMap = {}
  inputs.forEach((cfg) => (inputMap[cfg.name] = cfg))

  const doCalbackAndHandleResponse = useCallback(
    (variables) => {
      const result = callback(variables, form)

      // return, in order for submitted/submitting to be set apropriately
      // cast anything to a promise
      return Promise.resolve(result)
        .then((data) => notifySuccess(data, successMessage))
        .catch((err) => {
          notifyError(err, errorMessage)
          setError('api', {type: 'manual', message: err.message})
          // const fieldErrors = parseApiErrors(err)
          // Object.keys(fieldErrors).map((fieldName) =>
          //   setError(fieldName, {type: 'server', message: fieldErrors[fieldName].join(', ')})
          // )
        })
    },
    [form, setError]
  )

  return (
    <Form
      {...rest}
      form={form}
      inputs={inputMap}
      onSubmit={(...args) => {
        clearErrors() // clear previous server errors in order for submit to work
        return handleSubmit(doCalbackAndHandleResponse)(...args)
      }}
    />
  )
}
