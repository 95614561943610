import * as Config from '../config'
import StepForm from '../StepForm'

export default function Form(props: Config.OrderFormProps) {
  const {inputs} = props

  return (
    <StepForm {...props}>
      <div className="w-full grid grid-cols-1 sm:grid-cols-4">
        <div className="col-span-3">{inputs['representant[school]'].Control}</div>
        {inputs['representant[class]'].Control}
      </div>
      {inputs['representant[city]'].Control}
    </StepForm>
  )
}
Form.Config = [Config.SchoolConfig, Config.ClassConfig, Config.CityConfig]
