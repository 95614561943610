import React from 'react'
import classNames from 'classnames'
import Icon, {IconName} from '@atom/Icon/Icon'

import style from './Button.module.css'

export interface ButtonProps {
  className?: string
  label?: string
  children?: string
  disabled?: boolean
  type?: 'button' | 'submit'
  icon?: IconName
  variant?: 'default' | 'primary' | 'outline' | 'clear'
  size?: 'sm' | 'md' | 'lg'

  /**
   * Action handler
   * @param e MouseEvent
   */
  onClick?: (e) => void
}

export default function Button({
  className,
  label,
  icon,
  variant = 'default',
  size = 'md',
  type = 'button',
  disabled = false,

  children,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNames(style.button, style[variant], style[size], className)}
    >
      {(label || children) && <span className={style.label}>{label || children}</span>}
      {icon && <Icon name={icon} className={style.icon} />}
    </button>
  )
}
