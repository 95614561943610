import FormContainer from '@form/components/FormContainer'
import ItemEditForm from './ItemEditForm'

export default function ItemEditFormContainer({onSubmit, defaultValues, ...rest}) {
  return (
    <FormContainer
      render={ItemEditForm}
      callback={onSubmit}
      defaultValues={defaultValues}
      successMessage={null}
      {...rest}
    />
  )
}
