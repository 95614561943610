import {deepMerge} from '@lib/api/merge'
import {useState} from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

const steps = [
  {
    label: 'Despre clasă',
    form: Step1,
    text:
      'Pentru a păstra într-un mod inedit cele mai frumoase amintiri realizate la ședințele foto, avem nevoie de câteva detalii din partea clasei tale.',
  },
  {
    label: 'Despre repezentant',
    form: Step2,
    text:
      'Pentru a păstra într-un mod inedit cele mai frumoase amintiri realizate la ședințele foto, avem nevoie de câteva detalii din partea clasei tale.',
  },
  {
    label: 'Data de predare a albumelor',
    form: Step3,
    text:
      'Termenul de editare, verificare și predare al albumelor photobook este de 30 de zile din momentul în care toată clasa își alege fotografiile. Astfel, te rugăm să alegi data de predare a albumelor pentru toată clasa în funcție de programarea ședințelor foto.',
  },
  {
    label: 'Detalii comandă',
    form: Step4,
    text:
      'Te rugăm să notezi numele colegilor tăi cu diacritice, exact cum își doresc să apară în album.',
  },
]

export default function OrderFormWizard({createCallback}) {
  const [data, setData] = useState({})
  const [step, setStep] = useState(1)
  const prev = () => setStep(step - 1)
  const next = () => setStep(step + 1)

  const submitStep = (newData) => {
    const fd = deepMerge(data, newData)
    setData(fd)
    console.log(fd)
    if (step < steps.length) next()
    else createCallback(fd)
  }
  const {form: Form, label, text = ''} = steps[step - 1]

  return (
    <div className="container flex flex-col items-center">
      <p className="max-w-lg text-left text-gray-body my-6">{text}</p>

      <p className="text-gray-ash text-lg text-center py-3">{label}</p>
      <Form onSubmit={submitStep} defaultValues={data} goBack={prev} step={step} />
    </div>
  )
}
