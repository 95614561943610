import Button from '@atom/Button'

export default function BottomButtons({isSubmitting, goBack, step}) {
  return (
    <div className="flex flex-row mt-10">
      {step > 1 && <Button onClick={goBack} variant="outline" label="Pasul anterior" />}
      <Button
        className="ml-5"
        disabled={isSubmitting}
        label="Pasul următor"
        variant="outline"
        type="submit"
      />
    </div>
  )
}
