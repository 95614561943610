import FormContainer from '@form/components/FormContainer'
import Form from './Form'
import {StepContainerProps} from '../types'

export default function StepContainer({onSubmit, defaultValues, ...rest}: StepContainerProps) {
  return (
    <FormContainer
      render={Form}
      callback={onSubmit}
      defaultValues={defaultValues}
      successMessage={null}
      {...rest}
    />
  )
}
