import {Controller} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'

import Select from '@atom/Select'
import TextInput from '@atom/TextInput'

import FormLabel from './FormLabel'
import FormError from './FormError'
import DefaultFormControl from './FormControl'
import {UseInput, InputConfig} from '../types'

function createInput(config: InputConfig, ref, {control}) {
  const {type, id, placeholder, name, value, component: Component, options} = config

  if (Component)
    return <Controller render={Component} name={name} control={control} defaultValue={value} />

  switch (type) {
    case 'select':
      return (
        <Select
          name={name}
          id={id}
          options={options}
          ref={ref}
          placeholder={placeholder}
          value={value}
        />
      )
    default:
      return <TextInput id={id} type={type} name={name} placeholder={placeholder} ref={ref} />
  }
}

const useInput = (form) => (config: InputConfig): UseInput => {
  const {register, errors} = form
  const {id, label, name, bind, component} = config
  const ref = register(bind)

  const Label = label ? <FormLabel id={id} label={label} /> : null
  const Error = <ErrorMessage errors={errors} name={name} render={FormError} />
  const Input = createInput(config, ref, form)

  const ControlComponent = component?.Control || DefaultFormControl

  const Control = <ControlComponent Label={Label} Input={Input} Error={Error} />

  return {
    Label,
    Input,
    Error,
    Control,
    name,
    label,
  }
}

export default useInput
