import Router from 'next/router'
import {useCallback, useState} from 'react'
import {submitOrder} from '@lib/api/order'
import {toastError, toastSuccess} from '@lib/toast'
import Modal from '@atom/Modal'
import Button from '@atom/Button'
import OrderFormWizard from './OrderFormWizard'

export default function OrderFormContainer() {
  const callback = useCallback(
    (data) =>
      submitOrder(data).then(
        (response) => {
          if (!response.data.success) throw new Error('Success error')
          toastSuccess(() => (
            <>
              <h1>Mulțumim!</h1>
              <p className="text-gray-body">
                Îți mulțumim pentru comanda plasată. Te vom contacta în scurt timp pentru
                confirmare.
              </p>
            </>
          ))
          Router.replace('/show-order?token=' + response.data.token)
        },
        (error) => {
          toastError(`Eroare la trimiterea cererii: ${error?.message || error}`)
          console.warn(error)
        }
      ),
    []
  )

  const [submission, setSubmission] = useState(false)

  return (
    <>
      <OrderFormWizard createCallback={setSubmission} />
      {submission && (
        <Modal>
          <div className="p-4">
            Ești sigur că ai plasat comanda corectă pentru clasa ta?
            <div className="flex gap-4 mt-6 justify-center">
              <Button
                onClick={() => setSubmission(false)}
                label="Nu"
                variant="outline"
                className="w-20"
              />
              <Button
                onClick={() => [setSubmission(false), callback(submission)]}
                label="Da"
                variant="primary"
                className="w-20"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
