import FormContainer from '@form/components/FormContainer'
import {useCallback} from 'react'
import Form from './Form'

export default function AddFormContainer({onSubmit, defaultValues, ...rest}) {
  const onSubmitCallback = useCallback(async (data, form) => {
    await onSubmit(data)
    form.reset()
  }, [])

  return (
    <FormContainer
      render={Form}
      callback={onSubmitCallback}
      defaultValues={defaultValues}
      successMessage={null}
      {...rest}
    />
  )
}
