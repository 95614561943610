/**
 * BRAND
 */
export const logoSimple = '/images/brand/logo/simple-dark.svg'
export const logoSimpleInversed = '/images/brand/logo/simple-white.svg'
export const logoFull = '/images/brand/logo/full-dark.svg'
export const logoFullInversed = '/images/brand/logo/full-white.svg'

/**
 * Icons
 */
export const icons = {
  forwardArrow: '/images/icon/Forward Arrow.png',
  backArrow: '/images/icon/Back Arrow.png',
  delete: '/images/icon/Delete.png',
  edit: '/images/icon/Edit.png',
  plus: '/images/icon/Plus.png',
  plusRed: '/images/icon/plus-red.svg',
  closeX: '/images/icon/Close X.png',
}
