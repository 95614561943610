import classnames from 'classnames'
import Icon from '@atom/Icon'
import React from 'react'

export default function OrderTable({
  students,
  register = null,
  remove = null,
  setEditIndex = null,
}) {
  return (
    <div className="border-2 border-gray-input rounded-xl w-full mt-4">
      <div
        className={classnames(
          'bg-gray-input rounded-t-xl w-full grid',
          register ? 'grid-cols-4' : 'grid-cols-3'
        )}
      >
        <div className="p-2">Nume, prenume</div>
        <div className="p-2">Pachet Classbook</div>
        <div className="p-2">Album & copertă</div>
        {register && <div className="p-2">Opțiuni</div>}
      </div>
      {students.map((item, index) => (
        <div
          key={index}
          className={classnames('w-full grid border-t-2', register ? 'grid-cols-4' : 'grid-cols-3')}
        >
          {register && (
            <>
              <input
                type="hidden"
                name={`items[${index}][name]`}
                value={item.name}
                ref={register()}
              />
              <input
                type="hidden"
                name={`items[${index}][service]`}
                value={item.service}
                ref={register()}
              />
            </>
          )}
          <div className="py-2">{item.name}</div>
          <div className="py-2 border-l-2">{item.service}</div>
          <div className="py-2 border-l-2">
            {item?.albums?.map(({size, cover}, index2) => (
              <div key={index2}>
                {size}, {cover}
                {register && (
                  <>
                    <input
                      type="hidden"
                      name={`items[${index}][albums][${index2}][size]`}
                      value={size}
                      ref={register()}
                    />
                    <input
                      type="hidden"
                      name={`items[${index}][albums][${index2}][cover]`}
                      value={cover}
                      ref={register()}
                    />
                  </>
                )}
              </div>
            ))}
          </div>

          {register && (
            <div className="flex justify-center py-2 border-l-2">
              <Icon
                name="edit"
                onClick={() => setEditIndex(index)}
                className="mr-4 cursor-pointer"
              />
              <Icon name="delete" onClick={() => remove(index)} className="cursor-pointer" />
            </div>
          )}
        </div>
      ))}
      {!students?.length && <h2>Oooooops! Nu ai colegi adăugați!</h2>}
    </div>
  )
}
